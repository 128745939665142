import React from "react";

import Layout, { Main } from "../components/Layout";
import styled from "styled-components";
import { useScript } from "../utils/useScript";
import Seo from "../components/Seo"

const VideoFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

const VideoWrapper = styled.div`
  padding: 56.25% 0 0 0;
  position: relative;
  margin: 0 8rem;
`;

const Text = styled.p`
  padding: 0.5rem 4rem 2rem;
  font-weight: 600;
`;

export const IndexPageTemplate = () => {
  const status = useScript("https://player.vimeo.com/api/player.js");
  return (
    <>
      <Seo title="Nigel Lendon" description="A website chronicling Nigel's life, works and texts."/>
      <VideoWrapper>
        <VideoFrame
          src="https://player.vimeo.com/video/153472091"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></VideoFrame>
      </VideoWrapper>
      <Text>Modelling the Now (ANU Drill Hall Gallery) Nov 2015 is Nigel Lendon's most recent survey exhibition.</Text>
    </>
  );
};

const IndexPage = () => {
  return (
    <Layout>
      <IndexPageTemplate />
    </Layout>
  );
};

export default IndexPage;
