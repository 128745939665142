import { Helmet } from "react-helmet";
import React from 'react'

const Seo = ({title, description}) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
  </Helmet>
)

export default Seo